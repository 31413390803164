import { Col, Container, Row, Nav, Navbar } from "react-bootstrap";
import "./App.css";
import Web3 from "web3";
import { ethers } from "ethers";
import { useEffect, useState } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import abi from "./abi.json";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

function App() {
  const [web3, setWeb3] = useState("");
  const [add, setAdd] = useState("");
  const [instance, setInstance] = useState(null);
  const [mintNumber, setMintNumber] = useState(1);
  const [totalMinted, setTotalMinted] = useState(0);

  useEffect(() => {
    let web3 = new Web3(Web3.givenProvider);
    setWeb3(web3);

    const ins = new web3.eth.Contract(
      abi,
      "0x23f126D6daB5FBE6DfbC18026Ab30a61908dE835"
    );
    setInstance(ins);

    if (window.ethereum) {
      const getTotalMinted = async () => {
        var tokenCount = await ins.methods.tokenCount().call();
        setTotalMinted(tokenCount);
      };
      getTotalMinted();
    }
  }, []);

  async function requestAccount() {
    console.log("Requesting account...");
    if (window.ethereum) {
      console.log("detected");

      try {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        setAdd(accounts[0]);
      } catch (error) {
        console.log("Error connecting...");
      }
    } else {
      window.open("https://metamask.app.link/dapp/geeksinsidersclub.com/");
    }
  }

  const mint = async () => {
    if (add) {
      if (instance) {
        console.log(
          await instance.methods.mint(mintNumber).send({
            from: add,
            value: (
              (await instance.methods.cost().call()) * mintNumber
            ).toString(),
          })
        );
      } else {
        console.log("error");
      }
    } else {
      alert("Please Connect your wallet");
    }
  };

  const plus = () => {
    if (mintNumber < 1) setMintNumber(mintNumber + 1);
  };
  const minus = () => {
    if (mintNumber > 1) {
      setMintNumber(mintNumber - 1);
    }
  };

  return (
    <>
      <div className="app">
        <div className="scene">
          <Container className="mt-3">
            <div className="cus-nav">
              <Container>
                <Navbar className="cus-nav" variant="dark" expand="lg">
                  <Navbar.Brand
                    href="#top"
                    className="font-weight-bold text-uppercase"
                  >
                    <img
                      className="img-fluid navlogo"
                      src={require("./assets/img/logo1.png").default}
                      alt=""
                    />
                    <h2 className="logotext">Cryptogeeks</h2>
                  </Navbar.Brand>
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ml-auto d-flex align-items-center">
                      <Nav.Link href="#about" className="link-style linkh">
                        About
                      </Nav.Link>
                      <Nav.Link href="#utility" className="link-style linkh">
                        Utility
                      </Nav.Link>
                      <Nav.Link href="#faq" className="link-style linkh">
                        Faq
                      </Nav.Link>
                      <Nav.Link
                        onClick={requestAccount}
                        className="link-style px-3 btn cusbtn"
                      >
                        <span className=" text-uppercase">
                          {add ? add.substring(0, 15) : "Connect Wallet"}
                        </span>
                      </Nav.Link>
                    </Nav>
                  </Navbar.Collapse>
                </Navbar>
              </Container>
            </div>
          </Container>

          <Container className="showcase">
            <div className="d-flex  justify-content-center align-items-center">
              <div className="text-center ">
                <div className="text-center">
                  <img
                    className="img-fluid"
                    src={require("./assets/img/logo.png").default}
                    alt=""
                  />
                </div>
                <div className="font-weight-bold  d-flex justify-content-center">
                  <div className="widset">
                    <p className="text-uppercase subtitle">
                      IL CLUB ESCLUSIVO DI CRYPTOGEEKS CON ACCESSO TRAMITE NFT
                    </p>
                    <div className="">
                      <a
                        href="https://forms.gle/y2Zcz5MzZpfAav7K9"
                        target="_blank"
                        className="btn cusbtn"
                        rel="noreferrer"
                      >
                        CANDIDATI ORA
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* =============== */}
            {/* <div className="">
              <Container className="d-flex justify-content-center">
                <div className="mint">
                  <div className="">
                    <h1 className="mih1 text-center  text-uppercase mb-3">
                      Mint Your Pass
                    </h1>
                    <p className="subtitle p-0 m-0 text-center  text-uppercase">
                      1 V1P ALPHRED PASS nft costs 0.33 eth.
                    </p>

                    <div className="d-flex justify-content-center align-items-center mt-5">
                      <div className="mx-3">
                        <img
                          onClick={minus}
                          className="plus-img"
                          src={require("./assets/img/4.png").default}
                          alt=""
                        />
                      </div>
                      <div className="display-number d-flex justify-content-center align-items-center">
                        <span className="number">{mintNumber}</span>
                      </div>
                      <div className="mx-3">
                        <img
                          onClick={plus}
                          className="plus-img"
                          src={require("./assets/img/2.png").default}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="text-center mt-5">
                      <button
                        onClick={mint}
                        className="btn cusbtn  text-uppercase"
                      >
                        Mint now
                      </button>
                    </div>
                    <div className="text-center mt-4">
                      <h3 className="font-weight-bold bfont">
                        {totalMinted} / 1000
                      </h3>
                    </div>
                  </div>
                </div>
              </Container>
            </div>
            */}
          </Container>
        </div>

        <div id="about" className="about-content">
          <div id="" className="">
            <Container className="">
              <div className="text-center">
                <h1
                  data-aos="fade-up"
                  data-aos-duration="2000"
                  className="title"
                >
                  I PILASTRI DEL CLUB
                </h1>
              </div>
              <Row className="con-1 h-100">
                <Col
                  data-aos="fade-up"
                  data-aos-duration="2000"
                  lg="4"
                  className="d-flex flex-column justify-content-between"
                >
                  <div className="">
                    <div className="">
                      <span className="exp-title">VALORE</span>
                    </div>
                    <div className="mt-2">
                      <span className="exp-subtitle">
                        Entrare nel Geeks Insiders Club e beneficiare dei perks
                        esclusivi destinati agli holders, ai quali verrà aperto
                        l’ accesso a sezioni esclusive che spazieranno da report
                        onchain a accesso anticipato ai percorsi, accesso al
                        discord di Geeks Insiders Club e molto altro …
                      </span>
                    </div>
                  </div>
                  <div className="con-1">
                    <div className="">
                      <span className="exp-title">COMPETENZA</span>
                    </div>
                    <div className="mt-2">
                      <span className="exp-subtitle">
                        La community di Geeks Insiders Club punta a diventare un
                        luogo di riunione di competenza e networking tra i
                        principali esperti del settore, proprio per questo
                        abbiamo deciso di non aprire a tutti ma di porre un form
                        di selezione per mantenere alto il livello di competenza
                        e professionalità.
                      </span>
                    </div>
                  </div>
                </Col>
                <Col
                  data-aos="fade-up"
                  data-aos-duration="2000"
                  lg="4"
                  className="rmt-3 d-flex align-items-center justify-content-center"
                >
                  <div className="">
                    <img
                      className="img-fluid"
                      src={require("./assets/img/gc.gif").default}
                      alt=""
                    />
                  </div>
                </Col>
                <Col
                  data-aos="fade-up"
                  data-aos-duration="2000"
                  lg="4"
                  className="rmt-3 d-flex flex-column justify-content-between"
                >
                  <div className="">
                    <div className="">
                      <span className="exp-title">COMMUNITY</span>
                    </div>
                    <div className="mt-2">
                      <span className="exp-subtitle">
                        Lo sviluppo di Geeks Insiders Club permetterà di
                        affrontare il mercato più affascinante dell'ultimo
                        secolo insieme a una community proattiva e creativa
                      </span>
                    </div>
                  </div>
                  <div className="con-1">
                    <div className="">
                      <span className="exp-title">VISIONE</span>
                    </div>
                    <div className="mt-2">
                      <span className="exp-subtitle">
                        Scardinare l’ inflazionata visione che pervade
                        attualmente il mondo degli NFT, puntando su tecnologia e
                        valore a discapito dei quick flip che creano community
                        senza coesione e con un'ottica di breve termine. Nelle
                        mire del progetto rientrano collaborazioni esclusive e
                        benefits per tutti gli holders.
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>

        <div id="utility" className="collective-content">
          <Container>
            <div className="">
              <div className="text-center">
                <div className="text-center">
                  <h1
                    data-aos="fade-up"
                    data-aos-duration="2000"
                    className="title font-weight-bold text-uppercase"
                  >
                    UTILITY
                  </h1>
                </div>
                <div style={{ marginTop: "6rem" }} className="font-weight-bold">
                  <div className="">
                    <Container data-aos="fade-up" data-aos-duration="2000">
                      <Row className="">
                        <Col className="d-flex align-items-bottom w-100" lg="6">
                          <div className="bb mx-2">
                            <p>
                              REWARD ANNUALE DI CRYPTOGEEKS AIRDROPPATO AGLI
                              HOLDERS;
                            </p>
                          </div>
                        </Col>
                        <Col
                          className="rmt-3 d-flex align-items-bottom "
                          lg="6"
                        >
                          <div className="w-100 bb mx-2">
                            <p>ACCESSO ALL’ AREA MEMBRI ;</p>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mt-5">
                        <Col className="d-flex align-items-bottom" lg="6">
                          <div className="bb w-100 mx-2">
                            <p>ACCESSO AL DISCORD DI GIC ;</p>
                          </div>
                        </Col>
                        <Col className="rmt-3 d-flex align-items-bottom" lg="6">
                          <div className="w-100 bb mx-2">
                            <p>
                              ACCESSO ALLA DAO E AL BOARD DI DECISIONE DI
                              CRYPTOGEEKS ;
                            </p>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mt-5">
                        <Col className="d-flex align-items-bottom" lg="6">
                          <div className="bb w-100 mx-2">
                            <p>
                              25% DI SCONTO SU TUTTI I PERCORSI CRYPTOGEEKS ;
                            </p>
                          </div>
                        </Col>
                        <Col className="rmt-3 d-flex align-items-bottom" lg="6">
                          <div className="w-100 bb mx-2">
                            <p>ONCHAIN REPORT SETTIMANALE ;</p>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mt-5">
                        <Col className="d-flex align-items-bottom" lg="6">
                          <div className="bb w-100 mx-2">
                            <p>NFT REPORT MENSILE ;</p>
                          </div>
                        </Col>
                        <Col className="rmt-3 d-flex align-items-bottom" lg="6">
                          <div className="w-100 bb mx-2">
                            <p>
                              ACCESSO AL SISTEMA DI AFFILIATE CRYPTOGEEKS 20%;
                            </p>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mt-5">
                        <Col className="d-flex align-items-bottom" lg="6">
                          <div className="bb w-100 mx-2">
                            <p>PERCORSO ONCHAIN GEEKS METRICS ;</p>
                          </div>
                        </Col>
                        <Col className="rmt-3 d-flex align-items-bottom" lg="6">
                          <div className="w-100 bb mx-2">
                            <p>CO-CREAZIONE GEEKS INDEX ;</p>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>

        <div id="milestone" className="collective-content">
          <Container>
            <div className="">
              <div className="text-center">
                <div className="text-center">
                  <h1
                    data-aos="fade-up"
                    data-aos-duration="2000"
                    className="title font-weight-bold text-uppercase"
                  >
                    MILESTONE DEL LANCIO
                  </h1>
                </div>
                <div style={{ marginTop: "6rem" }} className="font-weight-bold">
                  <div className="">
                    <Container data-aos="fade-up" data-aos-duration="2000">
                      <div className="timeline py-5">
                        <div className="containerr left">
                          <div className="content">
                            <h5 className="cfont">
                              APERTURA DELLE CANDIDATURE PER IL GEEKS INSIDERS
                              CLUB
                            </h5>
                            <p className="cfont">
                              Puoi compilare il form e candidarti per entrare
                              nel GIC
                            </p>
                          </div>
                        </div>
                        <div className="containerr right">
                          <div className="content cfont">
                            <h5>SELEZIONE DELLE CANDIDATURE</h5>
                            <p>
                              Selezioneremo manualmente le candidature per
                              mantenere alto il livello di competenza e
                              professionalità.
                            </p>
                          </div>
                        </div>
                        <div className="containerr left">
                          <div className="content cfont">
                            <h5>
                              ANNUNCIO DEI DETTAGLI PER IL LANCIO: DATA DI
                              LANCIO, MINT PRICE E NUMERO DI EDIZIONI
                            </h5>
                            <p>
                              Tutti i dettagli verranno inviati tramite email e
                              nel discord di GIC ( potrai accedere al discord
                              solo dopo la candidatura)
                            </p>
                          </div>
                        </div>
                        <div className="containerr right">
                          <div className="content cfont">
                            <h5> LANCIO DEL GEEKS INSIDERS CLUB</h5>
                            <p>
                              Ogni informazione riguardante il lancio verrà
                              inviata per email l’ unico sito ufficiale di geeks
                              insiders club è geeksinsidersclub.com
                            </p>
                          </div>
                        </div>
                      </div>
                    </Container>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>

      <div id="faq" className="faq text-center">
        <div className="d-flex justify-content-center align-items-center">
          <h1
            data-aos="fade-up"
            data-aos-duration="2000"
            className="title font-weight-bold text-uppercase"
          >
            FAQ
          </h1>
        </div>

        <Container className="mt-5">
          <Row>
            <Col lg="12">
              <Accordion>
                <AccordionItem
                  className="faqedit"
                  data-aos="fade-up"
                  data-aos-duration="2000"
                >
                  <AccordionItemHeading>
                    <AccordionItemButton className="con-bot text-center">
                      Quanto sarà il mint price?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p className="text-dark">
                      Il mint price verrà annunciato al ridosso del lancio .
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem
                  className="faqedit mt-3"
                  data-aos="fade-up"
                  data-aos-duration="2000"
                >
                  <AccordionItemHeading>
                    <AccordionItemButton className="con-bot text-center">
                      Perchè devo candidarmi ?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p className="text-dark">
                      La candidatura è necessaria per selezionare e curare al
                      meglio la community di Geeks Insiders Club
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem
                  className="faqedit mt-3"
                  data-aos="fade-up"
                  data-aos-duration="2000"
                >
                  <AccordionItemHeading>
                    <AccordionItemButton className="con-bot text-center">
                      Quale è la Roadmap ?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p className="text-dark">
                      La roadmap non è stata ancora rilasciata
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem
                  className="faqedit mt-3"
                  data-aos="fade-up"
                  data-aos-duration="2000"
                >
                  <AccordionItemHeading>
                    <AccordionItemButton className="con-bot text-center">
                      Esiste un canale discord?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p className="text-dark">
                      No, l’ accesso al discord di GIC sarà destinato solo agli
                      Holders
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="footer">
        <Container>
          <Row>
            <Col lg="6">
              <div className="rtc">
                <img
                  className="img-fluid flogo"
                  src={require("./assets/img/logo1.png").default}
                  alt=""
                />
              </div>
            </Col>
            <Col className="rmt-2" lg="6">
              <div className="rtc text-right">
                <span className="font-weight-bold">
                  © {new Date().getFullYear()} Geeks Insiders Club.
                </span>
              </div>
            </Col>
          </Row>
          {/* <div className="d-flex justify-content-between align-items-center">
            <div className="">
              <img
                className="navlogo img-fluid"
                src={require("./assets/img/logo1.png").default}
                alt=""
              />
            </div>
            <div className="text-center d-flex align-items-center">
              <>
                <span className="font-weight-bold">
                  © {new Date().getFullYear()} Geeks Insiders Club | All rights
                  reserved.
                </span>
              </>
            </div>
          </div> */}
        </Container>
      </div>
    </>
  );
}

export default App;
